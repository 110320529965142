import React, { useContext, useEffect, useState } from "react";
import SingleItem from "./SingleItem";
import { Offcanvas } from "react-bootstrap";
import OrderDetailsModal from "./OrderDetailsModal";
import { AuthContext } from "../../../../../../../../Contexts/AuthContext/AuthProvider";
import Loader from "../../../../../../../Loader/Loader";

const Position = () => {
  const { authUser } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, 1000);
    return () => clearInterval(interval);
  }, [data]);

  const fetchData = async () => {
    if (authUser?._id) {
      const data = await fetch(
        `https://api.hyperspacebuddy.com/api/user/live/trade/log/running/history/${authUser?._id}`
      );
      const apiResponse = await data.json();
      setData(apiResponse);
      if (apiResponse) {
        setLoading(false);
      }
    }
  };

  const [userData, setUserData] = useState([]);

  useEffect(() => {
    if (authUser?._id) {
      fetch(
        `https://api.hyperspacebuddy.com/api/user/auth/view/${authUser?._id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setUserData(data.data);
        });
    }
  }, [authUser?._id]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [OderId, setOrderId] = useState();

  const handleOrderDetails = (id) => {
    setOrderId(id);
    setShow(true);
  };

  if (loading) {
    return (
      <div className="pt-3">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="orders-profit-loss">
        <h5>Profit and Loss</h5>
        <h3
          className={
            data?.ProfitandLoss < 0 ? "text-danger-1" : "text-success-1"
          }
        >
          {!isNaN(data?.ProfitandLoss) &&
            parseFloat(data?.ProfitandLoss).toFixed(2)}
        </h3>
      </div>
      <div className="user-orders-balance-area">
        <div className="d-flex justify-content-between align-items-center pb-2">
          <p>Balance</p>
          <span>
            {!isNaN(userData?.balance) &&
              (
                parseFloat(data?.ProfitandLoss) + parseFloat(userData?.balance)
              ).toFixed(2)}
          </span>
        </div>
        <div className="d-flex justify-content-between align-items-center pb-2">
          <p>Current Margin</p>
          <span>{data?.TotalMargin}</span>
        </div>
      </div>

      <div className="orders-history">
        {data?.data?.length !== 0 ? (
          data?.data?.map((data, index) => {
            if (data) {
              return (
                <SingleItem
                  data={data}
                  index={index}
                  key={data._id}
                  handleOrderDetails={handleOrderDetails}
                ></SingleItem>
              );
            }
          })
        ) : (
          <>
            <div className="text-center h-50  pt-5">
              <h6>No records available</h6>
            </div>
          </>
        )}
      </div>

      <Offcanvas
        show={show}
        placement="bottom"
        className="user-trade-position-modal  user-history-modal-area"
      >
        <OrderDetailsModal OderId={OderId} handleClose={handleClose} />
      </Offcanvas>
    </>
  );
};

export default Position;
