import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../../Contexts/AuthContext/AuthProvider";
import MobileHistorySingleItems from "./MobileHistorySingleItems";
import GoldMiningHistoryDetails from "../../MobileHistoryPage/GoldMiningHistory/GoldMiningHistoryDetails";

const MobileGoldMiningHistory = ({ updateData }) => {
  const { authUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  const [GoldHistory, setGoldHistory] = useState([]);

  useEffect(() => {
    if (authUser?._id) {
      fetch(
        `https://api.hyperspacebuddy.com/api/user/mining/gold/history/view/${authUser?._id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setGoldHistory(data.data);
          setLoading(false);
        });
    }
  }, [authUser?._id, updateData]);

  const [singleDataId, setSingleDataId] = useState(null);
  const [Modal, setModalhow] = useState(false);

  const HandleModalShow = (id) => {
    setSingleDataId(id);
    setModalhow(true);
  };
  if (loading) {
    return <>{/* <Loader /> */}</>;
  } else {
    return (
      <>
        <section className="container">
          <div className="pb-3">
            <div className="mobile-history-section">
              <div className="mobile-history-title">
                <h4>Latest Mining</h4>
              </div>
              <div className="mobile-history-item-list">
                {GoldHistory?.length !== 0 ? (
                  GoldHistory.map((data, index) => {
                    if (data) {
                      return (
                        <MobileHistorySingleItems
                          data={data}
                          index={index}
                          key={data?._id}
                          HandleModalShow={HandleModalShow}
                        ></MobileHistorySingleItems>
                      );
                    }
                  })
                ) : (
                  <div className="text-center py-5">
                    <p className="text-dark">No records available</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>

        {(() => {
          if (Modal) {
            return (
              <GoldMiningHistoryDetails
                singleDataId={singleDataId}
                setShow={setModalhow}
                show={Modal}
              ></GoldMiningHistoryDetails>
            );
          }
        })()}

      </>
    );
  }
};

export default MobileGoldMiningHistory;
