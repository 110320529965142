import React, { useEffect, useState } from "react";
import SingleItem from "./SingleItem";

const Stock = ({ handleClose }) => {
   const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      fetch(`https://api.hyperspacebuddy.com/api/user/trade/market/stocks/mobile/filter/view`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setData(data.data);
          setLoading(false);
        });
    }, []);
  
    if(loading){
      return;
    }

  return (
    <>
      <div className="tab-content" id="pills-tabContent">
        <div className="tab-pane fade show active">
          <ul className="coin_menu mt-2">
            {data.map((data, index) => (
              <SingleItem
                data={data}
                key={data?._id}
                index={index}
                handleClose={handleClose}
              ></SingleItem>
            ))}

          </ul>
        </div>
      </div>
    </>
  );
};

export default Stock;
