import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

const SingleItem = ({ data, handleClose }) => {
  const [livePrice, setLivePrice] = useState([]);
  const [loading, setLoading] = useState(true);
  const { metals_id: metalsData } = data;

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, 1000);
    return () => clearInterval(interval);
  }, [livePrice]);

  const fetchData = async () => {
    if (metalsData !== null) {
      fetch(
        `https://api.polygon.io/v2/snapshot/locale/global/markets/forex/tickers/C:${metalsData?.symbol}?apiKey=${process.env.REACT_APP_POLYGON_API_KEY}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data?.ticker) {
            setLivePrice(data?.ticker);
            setLoading(false);
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  };

  if (loading || metalsData === null) {
    return;
  }

  return (
    <>
      <li>
        <NavLink
          onClick={handleClose}
          className="menu-item "
          to={`/user/live/trade/metals/${metalsData?._id}`}
        >
          <div className="d-flex justify-content-between gap-1">
            <div className="text-xl">
              <span>{metalsData?.name}</span>
              <span className="opacity-50 text-base font-light">
                <br /> {metalsData?.symbol}
              </span>
            </div>
            <div className="d-flex justify-content-between mt-1">
            {livePrice?.lastQuote?.a !== 0 && (
                <div className="text-gray-400 font-light text-sm  trade-sidebar-price">
                  {!isNaN(livePrice?.lastQuote?.a) &&
                    parseFloat(livePrice?.lastQuote?.a) +
                      parseFloat(metalsData?.price)}
                  $
                </div>
              )}
              {livePrice?.lastQuote?.a === 0 && (
                <div className="text-gray-400 font-light text-sm  trade-sidebar-price">
                  closed
                </div>
              )}
            </div>
          </div>
        </NavLink>
      </li>
    </>
  );
};

export default SingleItem;
