import axios from 'axios';
import React, { useEffect, useState } from 'react';
import AnnouncementModal from './AnnouncementModal';
import SingleItem from './SingleItem';
import "./MobileAnnouncement.css";
import Loader from '../../../Loader/Loader';
import MobileUserFooter from '../../Layouts/MobileUserFooter';

const MobileAnnouncement = () => {
    
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios
            .get(`https://api.hyperspacebuddy.com/api/user/notice/view`)
            .then((res) => {
                setData(res?.data?.data);
                setLoading(false)
            })
            .catch((err) => {
                console.log("API call error:", err?.message);
            });
    }, []);

    const [singleDataId, setSingleDataId] = useState(null);

    const [show, setShow] = useState(false);

    const NoticeModalShow = (id) => {
        setSingleDataId(id);
        setShow(true);
    };

    if(loading){
        return (
            <>
            <Loader />
            </>
        );
    }

    return (
        <>
         <section className="mobile-home-main">
         <section className="mobile-announcement-page-area">
            <div className="mobile-announcement-title">
                <h5>Announcement</h5>
            </div>
                    <div className="announcement-page">
                            {data?.length !== 0 ? (
                                data.map((data) => {
                                    if (data) {
                                        return (
    
                                            <SingleItem
                                                data={data} key={data?._id} NoticeModalShow={NoticeModalShow}
                                            ></SingleItem>
                                        );
                                    }
                                })
                            ) : (
                                <div className="announcement-page-item">
                                     <label for="announcement-page1" className="announcement-page-item-title" >
                                        No data available
                                    </label>
                                </div>
                            )}
                        </div>
    
    
                </section>
                <MobileUserFooter></MobileUserFooter>
                {(() => {
                    if (show) {
                        return (
                            <AnnouncementModal
                                singleDataId={singleDataId}
                                setShow={setShow}
                                show={show}
                            ></AnnouncementModal>
                        );
                    }
                })()}

         </section>
            


       
        </>
    );
};

export default MobileAnnouncement;