import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../../Contexts/AuthContext/AuthProvider';
import SingleItem from './SingleItem';
import WithdrawHistoryModal from '../../MobileHistoryPage/WithdrawHistory/WithdrawHistoryModal';

const MobileWithdrawHistory = () => {

  const { authUser } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (authUser?._id) {
      fetch(`https://api.hyperspacebuddy.com/api/user/withdrawal/view/${authUser?._id}`, {
        method: 'GET',
      })
        .then(res => res.json())
        .then(data => {
          setData(data.data);
          setLoading(false);

        });


    }

  }, [authUser?._id])




  const [singleDataId, setSingleDataId] = useState(null);
  const [Modal, setModalhow] = useState(false);

  const HandleModalShow = (id) => {
    setSingleDataId(id);
    setModalhow(true);
  };

  if (loading) {
    return ;
    
} else {
  return (
    <>
         <section className="container">
          <div className="pb-3">
          <div className="mobile-history-section">
            <div className="mobile-history-title">
              <h4>Latest Withdraw</h4>
            </div>
            <div className="mobile-history-item-list">
              {data?.length !== 0 ? (
                data.map((data, index) => {
                  if (data) {
                    return (
                      <SingleItem
                        data={data}
                        index={index}
                        key={data?._id}
                        HandleModalShow={HandleModalShow}
                      ></SingleItem>
                    );
                  }
                })
              ) : (
                <div className="text-center py-5">
                  <p className="text-dark">No records available</p>
                </div>
              )}
            </div>
          </div>
          </div>
        
        </section>

        
      {(() => {
        if (Modal) {
          return (
            <WithdrawHistoryModal
              singleDataId={singleDataId}
              setShow={setModalhow}
              show={Modal}
            ></WithdrawHistoryModal>
          );
        }
      })()}
    </>
  );
}
 
};

export default MobileWithdrawHistory;