import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const SingleItem = ({ data}) => {
  const [livePrice, setLivePrice] = useState([]);
   const [loading, setLoading] = useState(true);
   const { stocks_id: StocksData } = data;
 
   useEffect(() => {
     const interval = setInterval(() => {
       fetchData();
     }, 1000);
     return () => clearInterval(interval);
   }, [livePrice]);
 
   const fetchData = async () => {
     if (StocksData !== null) {
       fetch(
         `https://api.polygon.io/v2/snapshot/locale/us/markets/stocks/tickers/${StocksData?.symbol}?apiKey=${process.env.REACT_APP_POLYGON_API_KEY}`,
         {
           method: "GET",
         }
       )
         .then((res) => res.json())
         .then((data) => {
           if (data?.ticker) {
             setLivePrice(data?.ticker);
             setLoading(false);
           }
         })
         .catch((error) => {
           // console.log(error);
         });
     }
   };

   if (loading || StocksData === null) {
     return;
   }

  return (
    <>
       <Link
             to={`/user/trade/stock/${StocksData?._id}`}
             className="bg-white  hot-coin"
           >
             <div className="wrapper">
               <div className="d-flex align-items-center gap-2">
                 <div className="mr-2">
                   <LazyLoadImage
                     effect="blur"
                     alt="img"
                     src={`https://api.hyperspacebuddy.com/${StocksData?.image}`}
                     className="hot-coin-img"
                   />
                 </div>
                 <div className="flex-grow-1">
                   <div className="text-dark font-light">
                     <span className="font-medium text-lg">
                       {StocksData?.name}
                     </span>
                     <span className="text-base">
                       /{StocksData?.symbol}
                     </span>
                   </div>
                 </div>
               </div>
             </div>
             <div className="exchange">
               <div className="text-base font-light text-up">
                 {!isNaN(livePrice?.lastTrade?.p) && parseFloat(livePrice?.lastTrade?.p) + parseFloat(StocksData?.price)}$
               </div>
             </div>
           </Link>
    </>
  );
};

export default SingleItem;
