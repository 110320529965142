import React, { useContext, useEffect, useState } from "react";
import SingleItem from "./SingleItem";
import { Offcanvas } from "react-bootstrap";
import OrderDetailsModal from "../Position/OrderDetailsModal";
import { AuthContext } from "../../../../../../../../Contexts/AuthContext/AuthProvider";
import Loader from "../../../../../../../Loader/Loader";

const Pending = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [OderId, setOrderId] = useState();

  const handleOrderDetails = (id) => {
    setOrderId(id);
    setShow(true);
  };

  const { authUser } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (authUser?._id) {
      fetch(
        `https://api.hyperspacebuddy.com/api/user/live/trade/log/pending/history/${authUser?._id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setData(data?.data);
          setLoading(false);
        });
    }
  }, [show, authUser?._id]);

  if (loading) {
    return (
      <>
        <div className="pt-3">
          <Loader />
        </div>
      </>
    );
  }
  return (
    <>
      <div className="orders-history  pt-4">
        {data?.length !== 0 ? (
          data.map((data, index) => {
            if (data) {
              return (
                <SingleItem
                  data={data}
                  index={index}
                  key={data._id}
                  handleOrderDetails={handleOrderDetails}
                ></SingleItem>
              );
            }
          })
        ) : (
          <>
            <div className="text-center h-50 pt-5">
              <h6>No records available</h6>
            </div>
          </>
        )}
      </div>

      <Offcanvas
        show={show}
        placement="bottom"
        className="user-trade-position-modal user-history-modal-area"
      >
        <OrderDetailsModal OderId={OderId} handleClose={handleClose} />
      </Offcanvas>
    </>
  );
};

export default Pending;
