import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./ComplateTrade.css";
const ComplateTrade = (props) => {
  const { data, marketData, complateTradeModalShow } = props;

  const [ResultsData, setResults] = useState([]);
  const [userData, setUserData] = useState([]);
  const time = 1000 * parseFloat(data?.Time) + 2000;

  useEffect(() => {
    setTimeout(() => {
      fetch(
        `https://api.hyperspacebuddy.com/api/user/trade/log/single/view/${data?._id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setResults(data?.data);
          setUserData(data?.UserData);
        });
    }, time);
  }, [data, complateTradeModalShow]);

  if (ResultsData?.Result) {
    return (
      <>
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          className="mobile-trade-modal-section trade-results-modal-area posce-trade-modal mobile-trade-results-area"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {marketData?.Name ? marketData?.Name : marketData?.name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {ResultsData?.Result === "Draw" && (
              <>
                <div className="trade-results-win-icon">
                  <i className="fa-solid fa-circle-exclamation"></i>
                </div>
                <h4 className="text-dark text-center">$0.00 Draw</h4>
              </>
            )}
            {ResultsData?.Result === "Win" && (
              <>
                <div className="trade-results-win-icon">
                  <i className="fa-regular fa-circle-check"></i>
                </div>
                <h1 className="text-dark  text-center">
                  +
                  {parseFloat(
                    parseFloat(ResultsData?.Result_Amount) -
                      parseFloat(ResultsData?.Amount)
                  ).toFixed(2)}{" "}
                  Profit
                </h1>
              </>
            )}

            {ResultsData?.Result === "Loss" && (
              <>
                <div className="trade-results-loss-icon">
                  <i className="fa-regular fa-circle-xmark"></i>
                </div>
                <h1 className="text-danger text-center ">
                  -{parseFloat(ResultsData?.Amount).toFixed(2)} Loss
                </h1>
              </>
            )}

            <div class="result-details mt-4">
              <div className="trade-results">
                <p>Message:</p>
                {ResultsData?.Result === "Draw" && <p>Trade Draw</p>}
                {ResultsData?.Result === "Win" && <p>Trade Win</p>}
                {ResultsData?.Result === "Loss" && <p>Trade Lose</p>}
              </div>
              <div className="trade-results">
                <p>Balance:</p>
                <p>{parseFloat(userData?.balance).toFixed(2)}</p>
              </div>
              <div className="trade-results">
                <p>Selection period:</p>
                <p>{ResultsData?.Time}s</p>
              </div>
              <div className="trade-results">
                <p>Current price:</p>
                <p>{ResultsData?.Crypto_price}</p>
              </div>
              <div className="trade-results">
                <p>Direction:</p>
                <p>{ResultsData?.HighLow}</p>
              </div>
              <div className="trade-results">
                <p>Amount:</p>
                <p>{ResultsData?.Amount}</p>
              </div>
              {/* <div className="trade-results">
                <p> Post amount:</p>
                <p>{ResultsData?.Result_Amount}</p>
              </div> */}
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
};

export default ComplateTrade;
