import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const SingleItem = ({ data }) => {
  const [livePrice, setLivePrice] = useState([]);
  const [loading, setLoading] = useState(true);
  const { forex_id: forexData } = data;

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, 1000);
    return () => clearInterval(interval);
  }, [livePrice]);

  const fetchData = async () => {
    if (forexData !== null) {
      fetch(
        `https://api.polygon.io/v2/snapshot/locale/global/markets/forex/tickers/C:${forexData?.base_currency}${forexData?.quote_currency}?apiKey=${process.env.REACT_APP_POLYGON_API_KEY}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data?.ticker) {
            setLivePrice(data?.ticker);
            setLoading(false);
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  };

  if (loading || forexData === null) {
    return;
  }
  return (
    <>
      <Link
        to={`/user/trade/forex/${forexData?._id}`}
        className="bg-white  hot-coin"
      >
        <div className="wrapper">
          <div className="d-flex align-items-center gap-2">
            <div className="mr-2">
              <LazyLoadImage
                effect="blur"
                alt="img"
                src={`https://api.hyperspacebuddy.com/${forexData?.image}`}
                className="hot-coin-img"
              />
            </div>
            <div className="flex-grow-1">
              <div className="text-dark font-light">
                <span className="font-medium text-lg">{forexData?.name}</span>
                <span className="text-base">/{forexData?.base_currency}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="exchange">
          {livePrice?.lastQuote?.a !== 0 && (
            <div className="text-base font-light text-up">
              {!isNaN(livePrice?.lastQuote?.a) &&
                parseFloat(livePrice?.lastQuote?.a) +
                  parseFloat(forexData?.price)}
              $
            </div>
          )}
          {livePrice?.lastQuote?.a === 0 && (
            <div className="text-base font-light text-up">closed</div>
          )}
        </div>
      </Link>
    </>
  );
};

export default SingleItem;
