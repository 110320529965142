import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

const SingleItem = ({ data }) => {
  const [livePrice, setLivePrice] = useState([]);
  const [loading, setLoading] = useState(true);
  const { crypto_currency_id: cryptoCurrencyData } = data;

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, 1000);
    return () => clearInterval(interval);
  }, [livePrice]);

  const fetchData = async () => {
    if (cryptoCurrencyData !== null) {
      fetch(
        `https://api.polygon.io/v2/snapshot/locale/global/markets/crypto/tickers/X:${cryptoCurrencyData?.Symbol}${cryptoCurrencyData?.currency}?apiKey=${process.env.REACT_APP_POLYGON_API_KEY}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data?.ticker) {
            setLivePrice(data?.ticker);
            setLoading(false);
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  };

  if (loading || cryptoCurrencyData === null) {
    return;
  }
  return (
    <>
      <Link
        to={`/user/trade/crypto/${cryptoCurrencyData?._id}`}
        style={{ borderBottom: "1px solid #dedede" }}
        className="d-flex shadow-md mx-2 p-2"
      >
        <div className="align-self-center d-flex">
          <span className="icon rounded-s me-2 gradient-orange shadow-bg shadow-bg-s">
            <LazyLoadImage
              effect="blur"
              alt="img"
              className="coin_style_div_div-img"
              style={{ borderRadius: "50%" }}
              src={`https://api.hyperspacebuddy.com/${cryptoCurrencyData?.image}`}
            />
          </span>
        </div>
        <div className="align-self-center ps-1">
          <h5 style={{ color: "black" }} className="pt-1 text-base mb-n1">
            {cryptoCurrencyData?.Name}
          </h5>
          <p style={{ color: "green" }} className="mb-0 font-11 opacity-50">
            $ {!isNaN(livePrice?.lastTrade?.p) && parseFloat(livePrice?.lastTrade?.p) + parseFloat(cryptoCurrencyData?.price)}
            <span className="copyright-year"></span>
          </p>
        </div>
        <div className="align-self-center ms-auto text-end">
          <h4 style={{ color: "gray" }} className="pt-1 mb-n1 text-xs">
            Trade
          </h4>
        </div>
      </Link>
    </>
  );
};

export default SingleItem;
