import React, { useEffect, useState } from "react";
import SingleItem from "./SingleItem";

const Metal = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(
      `https://api.hyperspacebuddy.com/api/user/trade/market/metals/mobile/filter/view`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setData(data.data);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return;
  }
  return (
    <>
      <div id="a1" className="containerTab">
        <div
          className="d-flex pb-2 coin_style_div gap-2 flex-column mb-3 mt-1"
          id="coinContainer"
        >
          <h6
            style={{ backgroundColor: "#FF1493" }}
            className="text-center coin_style_h6 py-2"
          >
            Metal
          </h6>
        </div>
      </div>

      <div className="tab-content" id="pills-tabContent">
        <div className="tab-pane fade show active">
          <ul className="coin_menu mt-2">
            {data.map((data, index) => (
              <SingleItem
                data={data}
                key={data?._id}
                index={index}
              ></SingleItem>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Metal;
