import axios from "axios";
import dateFormat from "dateformat";
//gets 500 candle sticks
export const GetCandles = (currentTimeFrame, currentCoin) => {
    const date = new Date();
    let d = new Date();
    d.setDate(d.getDate() - currentTimeFrame?.day);
    // AAPL
    let lastDate = dateFormat(date, "yyyy-mm-dd");
    let preDate = dateFormat(d, "yyyy-mm-dd");
   const url = `https://api.polygon.io/v2/aggs/ticker/${currentCoin}/range/${currentTimeFrame?.period}/${currentTimeFrame?.period_type}/${preDate}/${lastDate}?adjusted=true&sort=desc&limit=${currentTimeFrame?.limit}&apiKey=${process.env.REACT_APP_POLYGON_API_KEY}`
  return axios.get(url);
};

//gets current candle stick info from binance
export const GetLiveCandle = () => {
  return (
    "wss://delayed.polygon.io/stocks"
  );
};



//gets current candle stick info from binance
export const GetAgre = async (currentTimeFrame, currentCoin) => {
    const date = new Date();
    let d = new Date();
    d.setDate(d.getDate() - currentTimeFrame?.day);
    // AAPL
    let lastDate = dateFormat(date, "yyyy-mm-dd");
    let preDate = dateFormat(d, "yyyy-mm-dd");
    
 const url = `https://api.polygon.io/v2/aggs/ticker/${currentCoin}/range/${currentTimeFrame?.period}/${currentTimeFrame?.period_type}/${preDate}/${lastDate}?adjusted=true&sort=desc&limit=${currentTimeFrame?.period}&apiKey=${process.env.REACT_APP_POLYGON_API_KEY}`;
return axios.get(url);
};
