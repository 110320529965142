import React, { useContext, useEffect, useState } from "react";
import "./MobileAssets.css";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import Loader from "../../../Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
const MobileAssets = () => {
  const { authUser } = useContext(AuthContext);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (authUser?._id) {
      fetch(
        `https://api.hyperspacebuddy.com/api/user/assets/balance/view/${authUser?._id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setData(data);
          setLoading(false);
        });
    }
  }, [authUser?._id]);

  const [userData, setUserData] = useState({});

  useEffect(() => {
    if (authUser?._id) {
      fetch(`https://api.hyperspacebuddy.com/api/user/auth/view/${authUser?._id}`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setUserData(data.data);
        });
    }
  }, [authUser?._id]);

  //////////////// live Prices

  const [BTCUSDLiveData, setBTCUSDLiveData] = useState({});
  const [ETHUSDLiveData, setETHUSDLiveData] = useState({});
  const [SOLUSDLiveData, setSOLUSDLiveData] = useState({});
  const [XAUUSDLiveData, setXAUUSDLiveData] = useState({});
  const [USDTUSDLiveData, setUSDTUSDLiveData] = useState({});
  const [XRPUSDLiveData, setXRPUSDLiveData] = useState({});

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, 1000);
    return () => clearInterval(interval);
  }, [BTCUSDLiveData]);

  const fetchData = async () => {
    try {
      fetch(
        `https://api.polygon.io/v2/snapshot/locale/global/markets/crypto/tickers/X:BTCUSD?apiKey=${process.env.REACT_APP_POLYGON_API_KEY}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data?.ticker) {
            setBTCUSDLiveData(data?.ticker);
          }
        })
        .catch((error) => {
          // console.log(error);
        });

      fetch(
        `https://api.polygon.io/v2/snapshot/locale/global/markets/crypto/tickers/X:ETHUSD?apiKey=${process.env.REACT_APP_POLYGON_API_KEY}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data?.ticker) {
            setETHUSDLiveData(data?.ticker);
          }
        })
        .catch((error) => {
          // console.log(error);
        });

      fetch(
        `https://api.polygon.io/v2/snapshot/locale/global/markets/crypto/tickers/X:SOLUSD?apiKey=${process.env.REACT_APP_POLYGON_API_KEY}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data?.ticker) {
            setSOLUSDLiveData(data?.ticker);
          }
        })
        .catch((error) => {
          // console.log(error);
        });

      fetch(
        `https://api.polygon.io/v2/snapshot/locale/global/markets/forex/tickers/C:XAUUSD?apiKey=${process.env.REACT_APP_POLYGON_API_KEY}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data?.ticker) {
            setXAUUSDLiveData(data?.ticker);
          }
        })
        .catch((error) => {
          // console.log(error);
        });

      fetch(
        `https://api.polygon.io/v2/snapshot/locale/global/markets/crypto/tickers/X:USDTUSD?apiKey=${process.env.REACT_APP_POLYGON_API_KEY}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data?.ticker) {
            setUSDTUSDLiveData(data?.ticker);
          }
        })
        .catch((error) => {
          // console.log(error);
        });

      fetch(
        `https://api.polygon.io/v2/snapshot/locale/global/markets/crypto/tickers/X:XRPUSD?apiKey=${process.env.REACT_APP_POLYGON_API_KEY}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data?.ticker) {
            setXRPUSDLiveData(data?.ticker);
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    } catch (error) {}
  };
const [balanceShow, setBalanceShow] = useState(false);

  if (loading) {
    return (
      <>
        <Loader></Loader>
      </>
    );
  }

  return (
    <>
      <section className="assets-area">
        <div className="assets-user-balance-area">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4>
                  Total Balance <FontAwesomeIcon icon={faEye} onClick={()=>setBalanceShow(!balanceShow)} className={`${balanceShow ? "text-danger" : ""}`} />
                </h4>
                <div className="assets-user-balance">
                  <h5>
                    {!balanceShow  ? '******' : !isNaN(userData?.balance) &&
                      parseFloat(userData?.balance).toFixed(2)}
                  </h5>
                  <span>
                    USDT <i className="fa-solid fa-caret-down"></i>
                  </span>
                </div>
                <div className="assets-user-sub-balance">
                  <p>
                    ≈ $
                    {!balanceShow  ? '******' : !isNaN(userData?.balance) &&
                      parseFloat(userData?.balance).toFixed(2)}
                  </p>
                </div>
              </div>
              <Link to="/user/assets/exchange" className="user-btn1">
                Exchange
              </Link>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="assets-link-area">
            <Link to="/user/assets/deposit">
              <i
                className="fa-solid fa-money-bill-1-wave"
                style={{ color: "#536DFE" }}
              ></i>
              <p>Deposit</p>
            </Link>
            <Link to="/user/assets/withdraw">
              <i className="fa-solid fa-arrow-up-from-bracket"></i>
              <p>Withdraw</p>
            </Link>
            <Link to="/user/new/coin">
              <i className="fa-solid fa-coins" style={{ color: "green" }}></i>
              <p>New Coin</p>
            </Link>
            <a
              href="https://www.simplex.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i
                className="fa-solid fa-shop-lock"
                style={{ color: "#ff00f2" }}
              ></i>
              <p>Buy Coin</p>
            </a>
          </div>

          <div className="assets-bottom-balance-area">
            <div className="assets-bottom-balances-item">
              <div className="assets-bottom-balances">
                <div className="assets-bottom-balance-name">
                  <h5>Bitcoin</h5>
                </div>
                <div className="assets-bottom-balance">
                  <h6>
                    {data?.Bitcoin
                      ? parseFloat(data?.Bitcoin?.amount).toFixed(2)
                      : parseFloat(0).toFixed(2)}
                    USDT
                  </h6>
                  <p>
                    $
                    {data?.Bitcoin
                      ? parseFloat(data?.Bitcoin?.amount).toFixed(2)
                      : parseFloat(0).toFixed(2)}
                  </p>
                </div>
              </div>
              <div className="assets-bottom-balances">
                <div className="assets-bottom-balance-name">
                  <p>Price</p>
                </div>
                <div className="assets-bottom-balance">
                  <span>
                    $
                    {!isNaN(BTCUSDLiveData?.lastTrade?.p)
                      ? parseFloat(BTCUSDLiveData?.lastTrade?.p)
                      : 0}
                  </span>
                </div>
              </div>
            </div>
            <div className="assets-bottom-balances-item">
              <div className="assets-bottom-balances">
                <div className="assets-bottom-balance-name">
                  <h5>Ethereum</h5>
                </div>
                <div className="assets-bottom-balance">
                  <h6>
                    {data?.Ethereum
                      ? parseFloat(data?.Ethereum?.amount).toFixed(2)
                      : parseFloat(0).toFixed(2)}
                    USDT
                  </h6>
                  <p>
                    $
                    {data?.Ethereum
                      ? parseFloat(data?.Ethereum?.amount).toFixed(2)
                      : parseFloat(0).toFixed(2)}
                  </p>
                </div>
              </div>
              <div className="assets-bottom-balances">
                <div className="assets-bottom-balance-name">
                  <p>Price</p>
                </div>
                <div className="assets-bottom-balance">
                  <span>
                    $
                    {!isNaN(ETHUSDLiveData?.lastTrade?.p)
                      ? parseFloat(ETHUSDLiveData?.lastTrade?.p)
                      : 0}
                  </span>
                </div>
              </div>
            </div>
            <div className="assets-bottom-balances-item">
              <div className="assets-bottom-balances">
                <div className="assets-bottom-balance-name">
                  <h5>Solana</h5>
                </div>
                <div className="assets-bottom-balance">
                  <h6>
                    {data?.Solana
                      ? parseFloat(data?.Solana?.amount).toFixed(2)
                      : parseFloat(0).toFixed(2)}
                    USDT
                  </h6>
                  <p>
                    $
                    {data?.Solana
                      ? parseFloat(data?.Solana?.amount).toFixed(2)
                      : parseFloat(0).toFixed(2)}
                  </p>
                </div>
              </div>
              <div className="assets-bottom-balances">
                <div className="assets-bottom-balance-name">
                  <p>Price</p>
                </div>
                <div className="assets-bottom-balance">
                  <span>
                    $
                    {!isNaN(SOLUSDLiveData?.lastTrade?.p)
                      ? parseFloat(SOLUSDLiveData?.lastTrade?.p)
                      : 0}
                  </span>
                </div>
              </div>
            </div>
            <div className="assets-bottom-balances-item">
              <div className="assets-bottom-balances">
                <div className="assets-bottom-balance-name">
                  <h5>Gold</h5>
                </div>
                <div className="assets-bottom-balance">
                  <h6>
                    {data?.Gold
                      ? parseFloat(data?.Gold?.amount).toFixed(2)
                      : parseFloat(0).toFixed(2)}
                    USDT
                  </h6>
                  <p>
                    $
                    {data?.Gold
                      ? parseFloat(data?.Gold?.amount).toFixed(2)
                      : parseFloat(0).toFixed(2)}
                  </p>
                </div>
              </div>
              <div className="assets-bottom-balances">
                <div className="assets-bottom-balance-name">
                  <p>Price</p>
                </div>
                <div className="assets-bottom-balance">
                  <span>
                    $
                    {!isNaN(XAUUSDLiveData?.lastQuote?.a)
                      ? parseFloat(XAUUSDLiveData?.lastQuote?.a)
                      : 0}
                  </span>
                </div>
              </div>
            </div>
            <div className="assets-bottom-balances-item">
              <div className="assets-bottom-balances">
                <div className="assets-bottom-balance-name">
                  <h5>Tether</h5>
                </div>
                <div className="assets-bottom-balance">
                  <h6>
                    {data?.Tether
                      ? parseFloat(data?.Tether?.amount).toFixed(2)
                      : parseFloat(0).toFixed(2)}
                    USDT
                  </h6>
                  <p>
                    $
                    {data?.Tether
                      ? parseFloat(data?.Tether?.amount).toFixed(2)
                      : parseFloat(0).toFixed(2)}
                  </p>
                </div>
              </div>
              <div className="assets-bottom-balances">
                <div className="assets-bottom-balance-name">
                  <p>Price</p>
                </div>
                <div className="assets-bottom-balance">
                  <span>
                    $
                    {!isNaN(USDTUSDLiveData?.lastTrade?.p)
                      ? parseFloat(USDTUSDLiveData?.lastTrade?.p)
                      : 0}
                  </span>
                </div>
              </div>
            </div>
            <div className="assets-bottom-balances-item">
              <div className="assets-bottom-balances">
                <div className="assets-bottom-balance-name">
                  <h5>Xrp (Ripple)</h5>
                </div>
                <div className="assets-bottom-balance">
                  <h6>
                    {data?.XrpRipple
                      ? parseFloat(data?.XrpRipple?.amount).toFixed(2)
                      : parseFloat(0).toFixed(2)}
                    USDT
                  </h6>
                  <p>
                    $
                    {data?.XrpRipple
                      ? parseFloat(data?.XrpRipple?.amount).toFixed(2)
                      : parseFloat(0).toFixed(2)}
                  </p>
                </div>
              </div>
              <div className="assets-bottom-balances">
                <div className="assets-bottom-balance-name">
                  <p>Price</p>
                </div>
                <div className="assets-bottom-balance">
                  <span>
                    $
                    {!isNaN(XRPUSDLiveData?.lastTrade?.p)
                      ? parseFloat(XRPUSDLiveData?.lastTrade?.p)
                      : 0}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MobileAssets;
