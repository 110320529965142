import React from "react";
import dateFormat from "dateformat";
const SingleItem = ({ data, handleOrderDetails }) => {
  return (
    <>
      <div className="orders-history-item" onClick={()=>handleOrderDetails(data?._id)}>
        <div className="orders-history-title">
          <h5>{data?.pairs}</h5>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="orders-item-details">
            <p>
              {data?.lower_unit_price} &gt; {data?.current_price}
            </p>
            <span>Orders ID {data?.transaction}</span>
            <span> {dateFormat(data?.createdAt, "dd-mm-yyyy h:MM TT")}</span>
          </div>
          <div className={data?.direction === "Buy" ? "orders-item-up-value" : "orders-item-dwon-value"}>
            <h6>
              <p>{data?.direction === "Buy" ? "Buy" : "Sell"}</p> <span>{data?.lots} Lots</span>
            </h6>
            <h5 className={parseFloat(data?.profit) < 0 ? "text-danger-1" : 'text-success-1'}>{parseFloat(data?.profit).toFixed(2)}</h5>
          </div>
        </div>
      </div>
      {/* <div className="orders-history-item">
        <div className="orders-history-title">
          <h5>XAUUSD</h5>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="orders-item-details">
            <p>63714.53 &gt; 60444.11</p>
            <span>Orders ID #170296</span>
            <span>2024-07-15 21:04:43</span>
          </div>
          <div className="orders-item-dwon-value">
            <h6>
              <p>Sell</p> <span>0.5 Lots</span>
            </h6>
            <h5 className="text-danger-1">169014.5</h5>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default SingleItem;
