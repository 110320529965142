import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

const SingleItem = ({ data }) => {
  const { slider_id: SingleData } = data;
  return (
    <>
      <Link to={`/user/slider/details/${SingleData?._id}`}>
        <LazyLoadImage
          alt="img"
          effect="blur"
          src={`https://api.hyperspacebuddy.com/${SingleData?.image_url}`}
        />
      </Link>
    </>
  );
};

export default SingleItem;
