import React, { useContext, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../Contexts/AuthContext/AuthProvider";
import logo from "./../../../logo.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { toast } from "react-toastify";

const MobileRegisterForm = () => {
  const { LoginWithEmail } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const userFrom = location.state?.from?.pathname || "/user/dashboard";

  const [errorMessage, setErrorMessage] = useState({});
  const refSubmitDis = useRef();

  const [userVerifyData, setUserVerifyData] = useState(null);

  const handleLogin = (event) => {
    event.preventDefault();
    refSubmitDis.current.setAttribute("disabled", true);
    const formData = new FormData(event.currentTarget);
    const InpuData = Object.fromEntries(formData);
    setErrorMessage();
    fetch(`https://api.hyperspacebuddy.com/api/user/auth/signup`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(InpuData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success === false) {
          if (data?.errors) {
            setErrorMessage(data.errors);
          } else {
            setErrorMessage(data);
          }
        } else {
          setUserVerifyData(data);
          event.target.reset();
          toast.success(`${data?.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        refSubmitDis.current.removeAttribute("disabled");
      })
      .catch((error) => {
        // console.log(error)
      });
  };

  const handleVerifyCode = async (event) => {
    event.preventDefault();
    refSubmitDis.current.setAttribute("disabled", true);
    const formData = new FormData(event.currentTarget);
    const InpuData = Object.fromEntries(formData);
    setErrorMessage();
    fetch(`https://api.hyperspacebuddy.com/api/user/auth/signup/email/verify`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        authorization: `Beare ${userVerifyData?.token}`,
      },
      body: JSON.stringify({ ...InpuData, data: userVerifyData?.data }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success === false) {
          if (data?.errors) {
            setErrorMessage(data.errors);
          } else {
            setErrorMessage(data);
          }
        } else {
          event.target.reset();
          const user = data;
          localStorage.setItem("PCX_STORE_ID", JSON.stringify(user.data));
          const expires = new Date(
            Date.now() + 365 * 24 * 60 * 60 * 1000
          ).toUTCString();
          document.cookie = `pcs_store_token=OiJpcWJhbDExMSIsInVzZXJfaWQiOiI2M2VhNmE3MmJ1c2VyX25hbWMzODM5NX0VzZXJfaWQiOiI2M2InVzZXJfaWQiOiI2M2VhNmE3MmU4N2U5ZWJkNGM; expires=${expires}; path=/`;
          if (user.data) {
            LoginWithEmail(user.data);
            navigate(userFrom, { replace: true });
          }
        }
        refSubmitDis.current.removeAttribute("disabled");
      })
      .catch((error) => {
        // console.log(error)
      });
  };

  const handleVerifyCodeResend = async (event) => {
    event.preventDefault();
    refSubmitDis.current.setAttribute("disabled", true);
    setErrorMessage();
    fetch(`https://api.hyperspacebuddy.com/api/user/auth/verify/code/resend`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(userVerifyData?.data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setUserVerifyData(data);
          toast.success(`${data?.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(`something is wrong`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        refSubmitDis.current.removeAttribute("disabled");
      })
      .catch((error) => {
        // console.log(error)
      });
  };

  const [passwordType, setPasswordType] = useState("password");
  const eyeActiveRef = useRef();

  const showPassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      eyeActiveRef.current.classList.add("active");
    } else {
      setPasswordType("password");
      eyeActiveRef.current.classList.remove("active");
    }
  };

  const [CPasswordType, setCPasswordType] = useState("password");
  const eyeCpasswordActiveRef = useRef();

  const showCPassword = () => {
    if (CPasswordType === "password") {
      setCPasswordType("text");
      eyeCpasswordActiveRef.current.classList.add("active");
    } else {
      setCPasswordType("password");
      eyeCpasswordActiveRef.current.classList.remove("active");
    }
  };

  return (
    <>
      <section className="body-section">
        <section className="login-section">
          <div className="login-area">
            <div className="login-card">
              <div className="login-img">
                <Link to="/">
                  <LazyLoadImage alt="img" effect="blur" src={logo} />
                </Link>
              </div>

              <div className="login-form-area">
                {userVerifyData?.data ? (
                  <>
                    <form onSubmit={handleVerifyCode}>
                      <div className="input-group">
                        <label htmlFor="code">Verify Code</label>
                        <input
                          type="text"
                          id="code"
                          name="code"
                          placeholder="Enter your verify code"
                        />
                        {errorMessage?.code && (
                          <span className="text-danger text-start ms-2 ">
                            {errorMessage?.code?.msg}
                          </span>
                        )}
                      </div>

                      <div className="submit-btn-area">
                        <button
                          type="submit"
                          className="button-1 submit-btn"
                          ref={refSubmitDis}
                        >
                          Confirm
                        </button>
                      </div>
                    </form>

                    <form onSubmit={handleVerifyCodeResend}>
                      <div className="submit-btn-area">
                        <button
                          type="submit"
                          className="button-1 submit-btn mt-3 mb-4"
                          ref={refSubmitDis}
                        >
                          Resend code
                        </button>
                      </div>
                    </form>
                  </>
                ) : (
                  <>
                    <form onSubmit={handleLogin}>
                      <div className="input-group">
                        <label htmlFor="code">First Name</label>
                        <input
                          type="text"
                          id="fname"
                          name="fname"
                          placeholder="Enter your first name"
                        />
                        {errorMessage?.fname && (
                          <span className="text-danger text-start ms-2 ">
                            {errorMessage?.fname?.msg}
                          </span>
                        )}
                      </div>
                      <div className="input-group">
                        <label htmlFor="lname">Last Name</label>
                        <input
                          type="text"
                          id="lname"
                          name="lname"
                          placeholder="Enter your last name"
                        />
                        {errorMessage?.lname && (
                          <span className="text-danger text-start ms-2 ">
                            {errorMessage?.lname?.msg}
                          </span>
                        )}
                      </div>
                      <div className="input-group">
                        <label htmlFor="email">Email</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Enter your email address"
                        />
                        {errorMessage?.email && (
                          <span className="text-danger text-start ms-2 ">
                            {errorMessage?.email?.msg}
                          </span>
                        )}
                      </div>
                      <div className="input-group toggle-eye">
                        <label htmlFor="code">Password</label>
                        <input
                          type={passwordType}
                          id="password"
                          name="password"
                          placeholder="Enter your password"
                        />
                        <button
                          type="button"
                          onClick={showPassword}
                          ref={eyeActiveRef}
                        >
                          <i className="fa fa-eye"></i>
                        </button>
                        {errorMessage?.password && (
                          <span className="text-danger text-start ms-2 ">
                            {errorMessage?.password?.msg}
                          </span>
                        )}
                      </div>

                      <div className="input-group toggle-eye ">
                        <label htmlFor="code">Confirm Password</label>
                        <input
                          type={CPasswordType}
                          id="cpassword"
                          name="cpassword"
                          placeholder="Enter your confirm password"
                        />
                        <button
                          type="button"
                          onClick={showCPassword}
                          ref={eyeCpasswordActiveRef}
                        >
                          <i className="fa fa-eye"></i>
                        </button>
                        {errorMessage?.cpassword && (
                          <span className="text-danger text-start ms-2 ">
                            {errorMessage?.cpassword?.msg}
                          </span>
                        )}
                      </div>

                      <div className="submit-btn-area">
                        <button
                          type="submit"
                          className="button-1 submit-btn"
                          ref={refSubmitDis}
                        >
                          Confirm
                        </button>
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default MobileRegisterForm;
