import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../../Contexts/AuthContext/AuthProvider";
import axios from "axios";
import SingleItem from "./SingleItem";
import CustomPaginationMobile from "../../../../CustomPagination/CustomPaginationMobile";
import GoldMiningHistoryDetails from "./GoldMiningHistoryDetails";

const GoldMiningHistory = () => {
    const { authUser } = useContext(AuthContext);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [paginateData, setPaginateData] = useState([]);
  
    useEffect(() => {
      if (authUser?._id) {
        fetch(
          `https://api.hyperspacebuddy.com/api/user/mining/gold/history/view/${authUser?._id}`,
          {
            method: "GET",
          }
        )
          .then((res) => res.json())
          .then((data) => {
            setData(data.data);
            setPaginateData(data);
            setLoading(false);
          });
      }
    }, [authUser?._id]);
  
    const handlePage = (event) => {
      try {
        const pageNumber = event?.selected + 1;
        const config = {
          headers: {
            "content-type": "application/json",
          },
        };
        axios
          .get(
            `https://api.hyperspacebuddy.com/api/user/mining/gold/history/view/${authUser?._id}?page=${pageNumber}`,
            config
          )
          .then((response) => {
            setData(response?.data?.data);
            setPaginateData(response?.data);
          })
          .catch((error) => {});
      } catch (error) {
        console.log(error);
      }
    };
  
    const [singleDataId, setSingleDataId] = useState(null);
    const [Modal, setModalhow] = useState(false);
  
    const HandleModalShow = (id) => {
      setSingleDataId(id);
      setModalhow(true);
    };
  
    if(loading){
      return;
    }
    return (
      <>
        <section className="mobile-history-item-list">
          {data?.length !== 0 ? (
            data.map((data, index) => {
              if (data) {
                return (
                  <SingleItem
                    data={data}
                    index={index}
                    key={data?._id}
                    HandleModalShow={HandleModalShow}
                    paginateData={paginateData}
                  ></SingleItem>
                );
              }
            })
          ) : (
            <div className="text-center pt-5">
              <p className="text-write">No records available</p>
            </div>
          )}
  
          {/* paginateLinks */}
          <CustomPaginationMobile
            data={paginateData}
            handlePage={handlePage}
          ></CustomPaginationMobile>
          {/* paginateLinks */}
        </section>
  
        {(() => {
          if (Modal) {
            return (
              <GoldMiningHistoryDetails
                singleDataId={singleDataId}
                setShow={setModalhow}
                show={Modal}
              ></GoldMiningHistoryDetails>
            );
          }
        })()}
      </>
    );
  };
export default GoldMiningHistory;