import React, { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import MobileLiveTradeSideBar from "../MobileLiveTradeSideBar/MobileLiveTradeSideBar";
import ForexCandlestickCharts from "./ForexCandlestickCharts/ForexCandlestickCharts";

const MobileForexLiveTrade = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (id) {
      fetch(`https://api.hyperspacebuddy.com/api/admin/forex/single/view/${id}`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setData(data?.data);
          setLoading(false);
        });
    }
  }, [id]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  if (loading) {
    return;
  }

  return (
    <>
      <div className="d-flex justify-content-evenly align-items center  trade-page-btn">
        <div className="row gutters">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="text-center">
              <Link to={`/user/trade/forex/${id}`} className="user-btn1  ">
                Binary Trade
              </Link>
            </div>
          </div>
        </div>

        <div className="row gutters">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="text-center">
              <Link className="user-btn1  active">Live Trade</Link>
            </div>
          </div>
        </div>
      </div>
      <ForexCandlestickCharts TradeData={data} setShow={setShow} />
      <Offcanvas
        show={show}
        onHide={handleClose}
        className="mobile-trade-offcanvas-area"
      >
        <MobileLiveTradeSideBar
          handleClose={handleClose}
        ></MobileLiveTradeSideBar>
      </Offcanvas>
    </>
  );
};

export default MobileForexLiveTrade;
