import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

const SingleItem = ({ data, handleClose }) => {
  const [livePrice, setLivePrice] = useState([]);
  const [loading, setLoading] = useState(true);
  const { stocks_id: StocksData } = data;
 
  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, 1000);
    return () => clearInterval(interval);
  }, [livePrice]);

  const fetchData = async () => {
    if (StocksData !== null) {
      fetch(
        `https://api.polygon.io/v2/snapshot/locale/us/markets/stocks/tickers/${StocksData?.symbol}?apiKey=${process.env.REACT_APP_POLYGON_API_KEY}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data?.ticker) {
            setLivePrice(data?.ticker);
            setLoading(false);
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  };

  if (loading || StocksData === null) {
    return;
  }
 
  return (
    <>
      <li>
        <NavLink
          onClick={handleClose}
          className="menu-item "
          to={`/user/trade/stock/${StocksData?._id}`}
          end={true}
        >
          <div className="d-flex justify-content-between gap-1">
            <div className="text-xl">
              <span>{StocksData?.name}</span>
              <span className="opacity-50 text-base font-light">
                <br /> {StocksData?.symbol}
              </span>
            </div>
            <div className="d-flex justify-content-between mt-1">
              <div className="text-gray-400 font-light text-sm  trade-sidebar-price">
                {!isNaN(livePrice?.lastTrade?.p) && parseFloat(livePrice?.lastTrade?.p) + parseFloat(StocksData?.price)}$
              </div>
            </div>
          </div>
        </NavLink>
      </li>
    </>
  );
};

export default SingleItem;
