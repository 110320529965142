import React, { useContext, useRef, useState } from "react";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import { toast } from "react-toastify";
import axios from "axios";
import MobileWithdrawHistory from "./MobileWithdrawHistory/MobileWithdrawHistory";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const MobileWithdrawPage = () => {
  const { authUser } = useContext(AuthContext);

  const [dataValue, setDataValue] = useState({});

  const handleInputBlur = (event) => {
    const value = event.target.value;
    const field = event.target.name;
    const newUser = { ...dataValue };
    newUser[field] = value;
    setDataValue(newUser);
  };

  const refSubmitDis = useRef();
  const navigate = useNavigate();

  const handleSubmitData = (event) => {
    event.preventDefault();
    refSubmitDis.current.setAttribute("disabled", true);
    if (dataValue?.NetworkType) {
      const storeData = { user_id: authUser?._id, ...dataValue };
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      axios
        .post(
          `https://api.hyperspacebuddy.com/api/user/withdrawal/confirm`,
          storeData,
          config
        )
        .then((data) => {
          event.target.reset();
          if (data?.data?.success) {
            Swal.fire({
              title: `${data.data.message}`,
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/user/dashboard");
              }
            });
          }
          refSubmitDis.current.removeAttribute("disabled");
        })
        .catch((error) => {
          if (error?.response?.data?.success === false) {
            toast.error(`${error?.response?.data?.message}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            refSubmitDis.current.removeAttribute("disabled");
          }
        });
    } else {
      toast.error(`Network field is required`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      refSubmitDis.current.removeAttribute("disabled");
    }
  };

  const [WithdrawShow, setWithdrawShow] = useState("Crypto");
  const handleWithdrawShow = (data) => {
    setWithdrawShow(data);
  };

  return (
    <>
      <section className="mobile-py-20-500 user-dashboard">
        <div className="user-profile-area">
          <div className="container my-4">
            <div className="row gutters">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="card h-100">
                  <div className="card-body">
                    <div className="row gutters">
                      <form onSubmit={handleSubmitData}>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                          <h6 className="mb-3 text-primary text-center">
                            Withdrawal
                          </h6>
                        </div>

                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                          <div className="form-group">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            >
                              Select Withdrawal Method
                            </label>
                            <select
                              name="GatewayId"
                              onChange={(e) =>
                                handleWithdrawShow(e.target.value)
                              }
                              className="form-select"
                              required
                            >
                              <option value="Crypto" selected>
                                Crypto Wallet Transfer
                              </option>
                              <option value="Bank">Bank Wire or P2P</option>
                            </select>
                          </div>
                        </div>

                        {WithdrawShow === "Crypto" && (
                          <>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                              <div className="form-group">
                                <label
                                  htmlFor="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  Network
                                </label>
                                <select
                                  name="NetworkType"
                                  onBlur={handleInputBlur}
                                  className="form-select"
                                  required
                                >
                                  <option selected disabled>
                                    select
                                  </option>
                                  <option value="BTC">BTC</option>
                                  <option value="ETH">ETH</option>
                                  <option value="USDT">USDT</option>
                                </select>
                              </div>
                            </div>

                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                              <div className="form-group">
                                <label
                                  htmlFor="exampleFormControlInput2"
                                  className="form-label"
                                >
                                  Amount
                                </label>
                                <input
                                  type="number"
                                  name="Amount"
                                  onBlur={handleInputBlur}
                                  className="form-control"
                                  id="exampleFormControlInput2"
                                  aria-describedby="emailHelp"
                                  required
                                />
                              </div>
                            </div>

                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                              <div className="form-group">
                                <label
                                  htmlFor="exampleFormControlInput2"
                                  className="form-label"
                                >
                                  Withdrawal Address
                                </label>
                                <input
                                  type="text"
                                  name="WithdrawalAddress"
                                  onBlur={handleInputBlur}
                                  className="form-control"
                                  id="exampleFormControlInput2"
                                  aria-describedby="emailHelp"
                                  required
                                />
                              </div>
                            </div>

                            <div className="row gutters">
                              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="text-center">
                                  <button
                                    type="submit"
                                    id="submit"
                                    className="user-btn1  px-5"
                                    ref={refSubmitDis}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        {WithdrawShow === "Bank" && (
                          <>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                              <div className="form-group">
                                <label
                                  htmlFor="exampleFormControlInput2"
                                  className="form-label"
                                >
                                  Please contact with our customer support to
                                  get the details.
                                  <br />
                                  <br />
                                  Thank You.
                                </label>
                              </div>
                            </div>
                          </>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <MobileWithdrawHistory />
      </section>
    </>
  );
};

export default MobileWithdrawPage;
