import React, { useEffect, useContext, useState, useRef } from "react";
import { Link } from "react-router-dom";
import "./MobileUserHeader.css";
import { AuthContext } from "../../../Contexts/AuthContext/AuthProvider";
import MobileSideBarMenu from "./MobileSideBarMenu/MobileSideBarMenu.js";
import axios from "axios";
import ChatPage from "../../LiveChatsPage/ChatPage/ChatPage.js";
const MobileUserHeader = () => {
  const { LoginWithEmail, authUser } = useContext(AuthContext);

  const [show, setShow] = useState(false);
  const ShowMenuRef = useRef();

  const handleShow = () => {
    if (show) {
      ShowMenuRef.current.style.display = "none";
      setShow(false);
    } else {
      ShowMenuRef.current.style.display = "block";
      setShow(true);
    }
  };

  const supportDropdownRef = useRef(null);
  const [isSupportActive, setIsSupportActive] = useState(false);
  const handleSupportDropdown = () => setIsSupportActive(!isSupportActive);

  const [supportData, setSupportData] = useState([]);

  useEffect(() => {
    axios
      .get(`https://api.hyperspacebuddy.com/api/admin/site/setting/social/support/view`)
      .then((res) => {
        setSupportData(res?.data?.data);
      })
      .catch((err) => {
        // console.log("API call error:", err?.message);
      });
  }, []);

  const [chatPage, setChatPage] = useState(false);
  const ChatPageShowRef = useRef();

  const ChatPageShow = () => {
    ChatPageShowRef.current.style.display = "block";
    setChatPage(true);
    setIsSupportActive(!isSupportActive);
  };
  const ChatPageHide = () => {
    ChatPageShowRef.current.style.display = "none";
    setChatPage(false);
  };

  return (
    <>
      <div className="user-mobile-header-section">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <div className="user-mobile-header-logo">
              <div onClick={handleShow}>
                <i className="fa-solid fa-user"></i>
              </div>
            </div>
            <div className="searchBarMain">
              <i className="fa-solid fa-magnifying-glass "></i>
              <input
                type="text"
                name="header-search"
                id="searchBarInput"
                placeholder="Search"
              />
            </div>
            <div className="user-mobile-header-icon">
              <div className="support-dropdown-menu">
                <button className="" onClick={handleSupportDropdown}>
                  <i className="fa-solid fa-headset"></i>
                </button>
                <nav
                  ref={supportDropdownRef}
                  className={`support-dropdown-menu-item ${
                    isSupportActive ? "active" : ""
                  }`}
                >
                  <ul>
                    <li>
                      <a
                        href={supportData?.telegram_link}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={handleSupportDropdown}
                      >
                        {supportData?.telegram_name}
                      </a>
                    </li>
                    <li>
                      <a
                        href={`mailto:${supportData?.email}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={handleSupportDropdown}
                      >
                        Email
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        rel="noopener noreferrer"
                        onClick={ChatPageShow}
                      >
                        Live Chat
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>

              <Link to="/user/announcement">
                <i className="fa-solid fa-bell"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div style={{ display: "none" }} ref={ShowMenuRef}>
        <MobileSideBarMenu
          handleShow={handleShow}
          authUser={authUser}
        ></MobileSideBarMenu>
      </div>

      <div style={{ display: "none" }} ref={ChatPageShowRef}>
        {(() => {
          if (chatPage) {
            return <ChatPage ChatPageHide={ChatPageHide}></ChatPage>;
          }
        })()}
      </div>
    </>
  );
};

export default MobileUserHeader;
