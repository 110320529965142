import React, { useContext, useEffect, useState } from "react";
import "./UserBalance.css";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
const UserBalance = () => {
  const { authUser } = useContext(AuthContext);

  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (authUser?._id) {
      fetch(`https://api.hyperspacebuddy.com/api/user/auth/view/${authUser?._id}`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setUserData(data.data);
          setLoading(false);
        });
    }
  }, [authUser?._id]);


  const [balanceShow, setBalanceShow] = useState(false);
  return (
    <>
      <section className="user-mobile-balance-section">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            <div className="user-balance">
              <p >Total balance (USDT) <FontAwesomeIcon icon={faEye} onClick={()=>setBalanceShow(!balanceShow)} className={`${balanceShow ? "text-danger" : ""}`} /> </p>
              <h5>{!balanceShow  ? '******' : !isNaN(userData?.balance) && parseFloat(userData?.balance).toFixed(2)}</h5>
              <span>≈ ${!balanceShow  ? '******' : !isNaN(userData?.balance) && parseFloat(userData?.balance).toFixed(2)}</span>
            </div>
            <Link to="/user/assets/deposit" className="user-btn1">
              Add funds
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserBalance;
