import React, {  useState } from "react";
import "./Orders.css";
import Pending from "./Pending/Pending";
import History from "./History/History";
import Position from "./Position/Position";

const Orders = () => {
  
  const [HistoryShow, setHistoryShow] = useState("Position");


  return (
    <>
      <section className="orders-area">
        <div className="container">
          <div className="orders-menu-area">
            <div className="d-flex justify-content-between align-items-center">
              <h5
                className={HistoryShow === "Position" ? "active" : ""}
                onClick={() => setHistoryShow("Position")}
              >
                Position holding
              </h5>
              <h5
                className={HistoryShow === "Pending" ? "active" : ""}
                onClick={() => setHistoryShow("Pending")}
              >
                Pending Orders
              </h5>
              <h5
                className={HistoryShow === "History" ? "active" : ""}
                onClick={() => setHistoryShow("History")}
              >
                History
              </h5>
            </div>
          </div>

     
          {HistoryShow === "Position" && <Position />}
          {HistoryShow === "Pending" && <Pending />}
          {HistoryShow === "History" && <History />}
        </div>
      </section>
    </>
  );
};

export default Orders;
