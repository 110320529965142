import React, { useState } from "react";
import Crypto from "./Crypto/Crypto";
import Forex from "./Forex/Forex";
import Metals from "./Metals/Metals";
import Stocks from "./Stocks/Stocks";

const Markets = () => {
  const [marketShow, setMarketShow] = useState("Crypto");

  const MarketShowHandle = (data) => {
    setMarketShow(data);
  };
  return (
    <>
      <div className="user-mobile-market market-area-sw mobile-market-view">
        <div className="  mobile-view">
          <div className="container-fluid">
            <ul
              className="nav nav-pills mt-3 d-flex justify-content-between align-item-center pb-2"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className={
                    marketShow === "Crypto" ? "nav-link active" : "nav-link "
                  }
                  id="pills-top-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-top"
                  type="button"
                  role="tab"
                  aria-controls="pills-top"
                  aria-selected="true"
                  onClick={() => MarketShowHandle("Crypto")}
                >
                  Cryptocurrency
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className={
                    marketShow === "Metals" ? "nav-link active" : "nav-link "
                  }
                  id="pills-stocks-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-stocks"
                  type="button"
                  role="tab"
                  aria-controls="pills-stocks"
                  aria-selected="false"
                  tabIndex="-1"
                  onClick={() => MarketShowHandle("Metals")}
                >
                  Metal
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={
                    marketShow === "Stocks" ? "nav-link active" : "nav-link "
                  }
                  id="pills-metals-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-metals"
                  type="button"
                  role="tab"
                  aria-controls="pills-metals"
                  aria-selected="false"
                  tabIndex="-1"
                  onClick={() => MarketShowHandle("Stocks")}
                >
                  Stock
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={
                    marketShow === "Forex" ? "nav-link active" : "nav-link "
                  }
                  id="pills-forex-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-forex"
                  type="button"
                  role="tab"
                  aria-controls="pills-forex"
                  aria-selected="false"
                  tabIndex="-1"
                  onClick={() => MarketShowHandle("Forex")}
                >
                  Forex
                </button>
              </li>
            </ul>

            {marketShow === "Crypto" && <Crypto></Crypto>}
            {marketShow === "Stocks" && <Stocks></Stocks>}
            {marketShow === "Forex" && <Forex></Forex>}
            {marketShow === "Metals" && <Metals></Metals>}
          </div>
        </div>
      </div>
    </>
  );
};

export default Markets;
