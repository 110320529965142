import React, { useEffect, useState } from "react";
import SingleItem from "./SingleItem";

const Crypto = ({ handleClose }) => {
   const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      fetch(`https://api.hyperspacebuddy.com/api/user/trade/market//crypto/currency/mobile/filter/view`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setData(data.data);
          setLoading(false);
        });
    }, []);
  
    if(loading){
      return;
    }

  return (
    <>
      <div className="tab-content" id="pills-tabContent">
        <div className="tab-pane fade show active">
          <ul className="coin_menu mt-2">
            {data.map((data, index) => (
              <SingleItem
                data={data}
                key={data?._id}
                index={index}
                handleClose={handleClose}
              ></SingleItem>
            ))}

            {/* <li>
              <a
                className="menu-item active"
                href="https://swoopventures.com/user/trades?coin=DOGE"
              >
                <div className="d-flex justify-content-between gap-1">
                  <div className="text-xl">
                    <span>DOGE</span>
                    <span className="opacity-50 text-base font-light">
                      /USDT
                    </span>
                  </div>

                  <div className="d-flex justify-content-between mt-1">
                    <div className="text-gray-400 font-light text-sm  trade-sidebar-price">
                      0.1
                    </div>
                  </div>
                </div>
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Crypto;
