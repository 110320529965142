import React, { useRef, useState } from "react";
import "./MobileTradeSideBar.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import Crypto from "./Crypto/Crypto";
import Energises from "./Energises/Energises";
import Forex from "./Forex/Forex";
import Indices from "./Indices/Indices";
import Metal from "./Metal/Metal";
import Stock from "./Stock/Stock";

const MobileTradeSideBar = ({ handleClose }) => {
  const [marketShow, setMarketShow] = useState("Crypto");

  const HandleMarketShow = (data) => {
    setMarketShow(data);
  };

  return (
    <>
      <Offcanvas.Header>
        <Offcanvas.Title>
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item">
              <button
                className={`nav-link  ${
                  marketShow === "Crypto" ? "active" : ""
                }`}
                onClick={() => HandleMarketShow("Crypto")}
              >
                Crypto
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link  ${
                  marketShow === "Metal" ? "active" : ""
                }`}
                onClick={() => HandleMarketShow("Metal")}
              >
                Metal
              </button>
            </li>

          
           

            <li className="nav-item">
              <button
                className={`nav-link  ${
                  marketShow === "Stocks" ? "active" : ""
                }`}
                onClick={() => HandleMarketShow("Stocks")}
              >
                Stocks
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link  ${
                  marketShow === "Forex" ? "active" : ""
                }`}
                onClick={() => HandleMarketShow("Forex")}
              >
                Forex
              </button>
            </li>
          </ul>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {marketShow === "Crypto" && (
          <>
            <div className="coin_canvas_menu">
              <Crypto handleClose={handleClose}></Crypto>
            </div>
          </>
        )}
        {marketShow === "Metal" && (
          <>
            <div className="coin_canvas_menu">
              <Metal handleClose={handleClose}></Metal>
            </div>
          </>
        )}

        {marketShow === "Energises" && (
          <>
            <div className="coin_canvas_menu">
              <Energises handleClose={handleClose}></Energises>
            </div>
          </>
        )}

        {marketShow === "Stocks" && (
          <>
            <div className="coin_canvas_menu">
              <Stock handleClose={handleClose}></Stock>
            </div>
          </>
        )}

        {marketShow === "Indices" && (
          <>
            <div className="coin_canvas_menu">
              <Indices handleClose={handleClose}></Indices>
            </div>
          </>
        )}

        {marketShow === "Forex" && (
          <>
            <div className="coin_canvas_menu">
              <Forex handleClose={handleClose}></Forex>
            </div>
          </>
        )}
      </Offcanvas.Body>
    </>
  );
};

export default MobileTradeSideBar;
