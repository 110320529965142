import React, { useState } from "react";
import "./MobileTradePage.css";
import Crypto from "./Market/Crypto/Crypto";
import Metal from "./Market/Metal/Metal";
import Stock from "./Market/Stock/Stock";
import Forex from "./Market/Forex/Forex";

const MobileTradePage = () => {
  const [marketShow, setMarketShow] = useState("Crypto");

  const MarketShowHandle = (data) => {
    setMarketShow(data);
  };

  return (
    <>
      <section className="mobile-user-trade-section">
        <div className="d-lg-none">
          <div className="bg-all mobile-view d-block d-lg-none">
            <section className="section_two">
              <div className="section_div container">
                <div className="section_div_div crypto">
                  <button
                    onClick={() => MarketShowHandle("Crypto")}
                    className="section_div_btn"
                  >
                    <i
                      className="fa-brands fa-bitcoin section_div_btn_svg"
                      style={{ color: "blue" }}
                    ></i>
                  </button>
                  <h6
                    onClick={() => MarketShowHandle("Crypto")}
                    style={{ color: "blue" }}
                    className={
                      marketShow === "Crypto"
                        ? `section_div_p active`
                        : `section_div_p`
                    }
                  >
                    Cryptocurrency
                  </h6>
                </div>

                <div className="section_div_div metal">
                  <button
                    onClick={() => MarketShowHandle("Metal")}
                    className="section_div_btn"
                  >
                    <i
                      style={{ color: "#ff1493" }}
                      className="fas bg-white section_div_i  fa-industry section_div_btn_svg"
                    ></i>
                  </button>
                  <h6
                    onClick={() => MarketShowHandle("Metal")}
                    className={
                      marketShow === "Metal"
                        ? `section_div_p active`
                        : `section_div_p`
                    }
                    style={{ color: "gray" }}
                  >
                    Metal
                  </h6>
                </div>
                <div className="section_div_div stock">
                  <button
                    onClick={() => MarketShowHandle("Stock")}
                    className="section_div_btn"
                  >
                    <i
                      className="fa-brands fa-meta section_div_btn_svg"
                      style={{ color: "orange" }}
                    ></i>
                  </button>
                  <h6
                    onClick={() => MarketShowHandle("Stock")}
                    className={
                      marketShow === "Stock"
                        ? `section_div_p active`
                        : `section_div_p`
                    }
                    style={{ color: "gray" }}
                  >
                    Stock
                  </h6>
                </div>


                <div className="section_div_div forex">
                  <button
                    onClick={() => MarketShowHandle("Forex")}
                    className="section_div_btn"
                  >
                    <i
                      className="fa-solid fa-sack-dollar section_div_btn_svg"
                      style={{ color: "orangered" }}
                    ></i>
                  </button>
                  <h6
                    onClick={() => MarketShowHandle("Forex")}
                    className={
                      marketShow === "Forex"
                        ? `section_div_p active`
                        : `section_div_p`
                    }
                    style={{ color: "gray" }}
                  >
                    Forex
                  </h6>
                </div>
               
              </div>
            </section>
            <div className="tab-content">
              {marketShow === "Crypto" && <Crypto></Crypto>}
              {marketShow === "Metal" && <Metal></Metal>}
              {marketShow === "Stock" && <Stock></Stock>}
              {marketShow === "Forex" && <Forex></Forex>}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MobileTradePage;
