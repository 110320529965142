import React, { useContext, useEffect, useState } from "react";
import SingleItem from "./SingleItem";
import { AuthContext } from "../../../../../Contexts/AuthContext/AuthProvider";
import InvestmentPlanHistoryDetails from "../../MobileHistoryPage/InvestmentPlanHistory/InvestmentPlanHistoryDetails";

const MobileInvestPlanHistory = () => {
  const { authUser } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(
      `https://api.hyperspacebuddy.com/api/user/investment/plan/history/view/${authUser?._id}`,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setData(data?.data);
        setLoading(false);
      });
  }, []);


  const [singleDataId, setSingleDataId] = useState(null);
  const [Modal, setModalhow] = useState(false);

  const HandleModalShow = (id) => {
    setSingleDataId(id);
    setModalhow(true);
  };

  if (loading) {
    return ;
  } else {
    return (
      <>
        <section className="container">
          <div className="pb-3">
          <div className="mobile-history-section">
            <div className="mobile-history-title">
              <h4>Latest Investment</h4>
            </div>
            <div className="mobile-history-item-list">
              {data?.length !== 0 ? (
                data.map((data, index) => {
                  if (data) {
                    return (
                      <SingleItem
                        data={data}
                        index={index}
                        key={data?._id}
                        HandleModalShow={HandleModalShow}
                      ></SingleItem>
                    );
                  }
                })
              ) : (
                <div className="text-center py-5">
                <p className="text-dark">No records available</p>
              </div>
              )}
            </div>
          </div>
          </div>
        
        </section>

        {(() => {
          if (Modal) {
            return (
              <InvestmentPlanHistoryDetails
                singleDataId={singleDataId}
                setShow={setModalhow}
                show={Modal}
              ></InvestmentPlanHistoryDetails>
            );
          }
        })()}

      </>
    );
  }
};

export default MobileInvestPlanHistory;
