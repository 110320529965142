import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import dateFormat from "dateformat";
import Countdown from 'react-countdown';
const GoldMiningHistoryDetails = ({ setShow, singleDataId, show }) => {
  const [singleData, setSingleData] = useState([]);
  const [loading, setLoading] = useState(true);
  const handleClose = () => {
    setShow(false);
    setSingleData([]);
    setLoading(false);
  };
  useEffect(() => {
    if (singleDataId) {
      fetch(
        `https://api.hyperspacebuddy.com/api/user/mining/single/history/view/${singleDataId}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setSingleData(data?.data);
          setLoading(false);
        });
    }
  }, [singleDataId]);


  const c_profit = parseFloat(singleData?.profit_amount) - parseFloat(singleData?.amount);

  // Renderer callback with condition
const renderer = ({days,  hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span >completed</span>;
    } else {
      // Render a countdown
      return <> <span >{days}d&nbsp;{hours}h&nbsp;{minutes}m&nbsp;{seconds}s</span></>;
    }
  };

  function ModalComponent(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="announcement-modal-show notices-modal-show "
        backdrop="static"
      >
        <div className="announcement-header">
          <h4>Details</h4>
          <button onClick={handleClose}>
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
        <Modal.Body>
          <div className="history-details-modal-area">
            <div className="container">
              <div className="history-details-modal-item">
                <p>Name</p>
                <span>{singleData?.promotion_name}</span>
              </div>
              <div className="history-details-modal-item">
                <p>Invest&nbsp;Amount</p>
                <span>${singleData?.amount}</span>
              </div>
              <div className="history-details-modal-item">
                <p>Total&nbsp;Return</p>
                <span>${singleData?.profit_amount}</span>
              </div>
              <div className="history-details-modal-item">
                <p>C.Profit</p>
                <span>${c_profit}</span>
              </div>
              <div className="history-details-modal-item">
                <p>Remaining</p>
                <Countdown
    date={singleData?.expired_time}
    renderer={renderer}
  />
              </div>
              <div className="history-details-modal-item">
                <p>End&nbsp;At</p>
                <span>
                  {dateFormat(singleData?.expired_time, "mmm,")}&nbsp;
                  {dateFormat(singleData?.expired_time, "dS")}&nbsp;
                  {dateFormat(singleData?.expired_time, "yyyy")}
                </span>
              </div>
              <div className="history-details-modal-item">
                <p>Invest&nbsp;Date</p>
                <span>
                  {dateFormat(singleData?.createdAt, "mmm,")}&nbsp;
                  {dateFormat(singleData?.createdAt, "dS")}&nbsp;
                  {dateFormat(singleData?.createdAt, "yyyy")}
                </span>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center align-items-center"></Modal.Footer>
      </Modal>
    );
  }
  if (loading) {
    return <></>;
  } else {
    return (
      <>
        <ModalComponent show={show} onHide={() => setShow(false)} />
      </>
    );
  }
};

export default GoldMiningHistoryDetails;
