import React, { createContext, useEffect, useState } from "react";
const getlocalStorageItem = () => {
  let userId = localStorage.getItem("PCX_STORE_ID");
  if (userId) {
    return JSON.parse(userId);
  } else {
    // console.log("is ok")

    return null;
  }
};

export const AuthContext = createContext("");

const AuthProvider = ({ children }) => {
  const [authId, setAuthId] = useState(getlocalStorageItem());
  const [authUser, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const LoginWithEmail = (data) => {
    setAuthId(data);
    // console.log("context api : ", data);
  };

  function getCookie(name) {
    const cookieValue = document.cookie.match(
      `(^|;)\\s*${name}\\s*=\\s*([^;]+)`
    );
    return cookieValue ? cookieValue.pop() : "";
  }

  let token = getCookie("pcs_store_token");


  useEffect(() => {
    if (authId) {
      fetch(`https://api.hyperspacebuddy.com/api/user/auth/view/${authId?.user_id}`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data?.success === false) {
            SignOut();
            return;
          }else{
            setUser(data.data);
            setLoading(false);
          }
          
        });
    } else {
      SignOut();
      setLoading(false);
      return;
    }
  }, [authId]);
  const SignOut = () => {
    document.cookie = `pcs_store_token=; expires=${new Date(
      0
    ).toUTCString()}; path=/`;
    localStorage.removeItem("PCX_STORE_ID");
    const data = null;
    LoginWithEmail(data);
  };
  const authInfo = { authUser, LoginWithEmail, setUser, token };
  if (loading) {
    return;
  }
  return (
    <AuthContext.Provider value={authInfo}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
