import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../Contexts/AuthContext/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const MobileLogoutCompnent = () => {
    const { LoginWithEmail, authUser } = useContext(AuthContext);
    const from = "/";
    const navigate = useNavigate();
    const [remove, setremove] = useState(false);
  
    const SignOut = () => {
      document.cookie = `pcs_store_token=; expires=${new Date(
        0
      ).toUTCString()}; path=/`;
      document.cookie = `mobile_version_pcs_store_token=; expires=${new Date(
        0
      ).toUTCString()}; path=/`;
      localStorage.removeItem("PCX_STORE_ID");
      const data = null;
      LoginWithEmail(data);
      if (authUser === null) {
        navigate(from, { replace: true });
        toast.error(`Signout Successfully`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      setremove(true);
    };
    const logout = localStorage.getItem("PCX_STORE_ID");
  
    useEffect(() => {
      if (!logout && remove === true) {
        navigate(from, { replace: true });
      }
    }, [logout, remove, navigate]);
    return (
        <>
                  <li>
                  <button className="menu-item text-danger" onClick={SignOut}>
                    <span
                      style={{
                        padding: "4px",
                        backgroundColor: "red",
                        borderRadius: "4px",
                        color: "#FFF",
                      }}
                    >
                      <i className="fas text-white  fa-sign-out-alt"></i>
                    </span>
                    Log Out
                  </button>
                </li>
        </>
    );
};

export default MobileLogoutCompnent;