import React, { useEffect }  from 'react';
import './psec-style.css';
import './psec-responsive.css';
import './lightTheme.css';
import './index.css';
import './responsive.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css'
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-image-crop/dist/ReactCrop.css'
import 'antd/dist/reset.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Aos from "aos";
import "aos/dist/aos.css"
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { isMobile } from 'react-device-detect';
import { ToastContainer } from 'react-toastify';
import LoginRoute from './Route/LoginRoute';
import ReSetPassword from './Components/Auth/ReSetPassword/ReSetPassword';
import ForgetPassword from './Components/Auth/ForgetPassword/ForgetPassword';
import UserPrivateRoute from './Route/UserPrivateRoute';
import MobileUserDashboard from './Components/Mobile/Home/MobileUserDashboard';
import MobileHomeMain from './Components/Mobile/Layouts/MobileHomeMain';
import MobileLoginRoute from './Route/MobileLoginRoute';
import MobileLoginForm from './Components/Auth/MobileAuth/MobileLoginForm';
import MobileRegisterForm from './Components/Auth/MobileAuth/MobileRegisterForm';
import UserMobilePrivateRoute from './Route/UserMobilePrivateRoute';
import MobileTradePage from './Components/Mobile/Pages/MobileTrade/MobileTradePage';
import MobileBitcoinMining from './Components/Mobile/Pages/MobileMining/MobileBitcoinMining/MobileBitcoinMining';
import MobileGoldMining from './Components/Mobile/Pages/MobileMining/MobileGoldMining/MobileGoldMining';
import MobileLoan from './Components/Mobile/Pages/MobileLoan/MobileLoan';
import MobileKycPrivateRoute from './Route/MobileKycPrivateRoute';
import News from './Components/Mobile/Pages/News/News';
import NewsDetails from './Components/Mobile/Pages/News/NewsDetails';
import MobileDepositHistory from './Components/Mobile/Pages/MobileDeposit/MobileDepositHistory/MobileDepositHistory';
import MobileWithdrawPage from './Components/Mobile/Pages/MobileWithdraw/MobileWithdrawPage';
import MobileWithdrawHistory from './Components/Mobile/Pages/MobileWithdraw/MobileWithdrawHistory/MobileWithdrawHistory';
import MobileTransaction from './Components/Mobile/Pages/MobileTransaction/MobileTransaction';
import MobileKycPage from './Components/Mobile/Pages/MobileKycPage/MobileKycPage';
import UserProfileUpdate from './Components/Mobile/Pages/User/UserProfileUpdate';
import UserPasswordChange from './Components/Mobile/Pages/User/UserPasswordChange';
import MobileAssets from './Components/Mobile/Pages/MobileAssets/MobileAssets';
import Markets from './Components/Mobile/Pages/Markets/Markets';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';
import MobileEnergisesTrade from './Components/Mobile/Pages/MobileTrade/MobileEnergisesTrade/MobileEnergisesTrade';
import MobileIndicesTrade from './Components/Mobile/Pages/MobileTrade/MobileIndicesTrade/MobileIndicesTrade';
import MobileStocksTrade from './Components/Mobile/Pages/MobileTrade/MobileStocksTrade/MobileStocksTrade';
import MobileContact from './Components/Mobile/Pages/MobileContact/MobileContact';
import MobileForex from './Components/Mobile/Pages/MobileForex/MobileForex';
import MobileStocks from './Components/Mobile/Pages/MobileStocks/MobileStocks';
import MobileTradeHistory from './Components/Mobile/Pages/MobileTrade/MobileTradeHistory/MobileTradeHistory';
import MobileCryptoDeposit from './Components/Mobile/Pages/MobileDeposit/MobileCryptoDeposit/MobileCryptoDeposit';
import PopularMarketTrade from './Components/Mobile/Pages/MobileTrade/PopularMarketTrade/PopularMarketTrade';
import MobileInvestPlan from './Components/Mobile/Pages/MobileInvestPlan/MobileInvestPlan';
import MobileInvestPlanHistory from './Components/Mobile/Pages/MobileInvestPlan/MobileInvestPlanHistory/MobileInvestPlanHistory';
import MobileLoanConfirmPage from './Components/Mobile/Pages/MobileLoan/MobileLoanConfirmPage/MobileLoanConfirmPage';
import MobileAnnouncement from './Components/Mobile/Pages/MobileAnnouncement/MobileAnnouncement';
import AnnouncementDetails from './Components/Mobile/Pages/MobileAnnouncement/AnnouncementDetails';
import MobileAssetsExchange from './Components/Mobile/Pages/MobileAssets/MobileAssetsExchange/MobileAssetsExchange';
import MobileBitcoinMiningHistory from './Components/Mobile/Pages/MobileMining/MobileBitcoinMining/MobileBitcoinMiningHistory';
import MobileGoldMiningHistory from './Components/Mobile/Pages/MobileMining/MobileGoldMining/MobileGoldMiningHistory';
import MobileLoanHistory from './Components/Mobile/Pages/MobileLoan/MobileLoanHistory';
import MobilePlatformApps from './Components/Mobile/Pages/MobilePlatformApps/MobilePlatformApps';
import MobileSilderDetails from './Components/Mobile/Home/MobileSilder/MobileSilderDetails';
import MobileNewCoin from './Components/Mobile/Pages/MobileNewCoin/MobileNewCoin';
import MobileGift from './Components/Mobile/Pages/MobileGift/MobileGift';
import MobileHistoryPage from './Components/Mobile/Pages/MobileHistoryPage/MobileHistoryPage';
import MobileNewCoinCandle from './Components/Mobile/Pages/MobileNewCoin/MobileNewCoinCandle';
import Orders from './Components/Mobile/Pages/MobileTrade/MobileTradeHistory/LiveTradeHistory/Orders/Orders';
import Desktop from './Components/Desktop/Desktop';
import MobileCryptoBinaryTrade from './Components/Mobile/Pages/MobileTrade/MobileBinaryTrade/MobileCryptoBinaryTrade/MobileCryptoBinaryTrade';
import MobileForexBinaryTrade from './Components/Mobile/Pages/MobileTrade/MobileBinaryTrade/MobileForexBinaryTrade/MobileForexBinaryTrade';
import MobileMetalBinaryTrade from './Components/Mobile/Pages/MobileTrade/MobileBinaryTrade/MobileMetalBinaryTrade/MobileMetalBinaryTrade';
import MobileCryptoLiveTrade from './Components/Mobile/Pages/MobileTrade/MobileLiveTrade/MobileCryptoLiveTrade/MobileCryptoLiveTrade';
import MobileForexLiveTrade from './Components/Mobile/Pages/MobileTrade/MobileLiveTrade/MobileForexLiveTrade/MobileForexLiveTrade';
import MobileMetalsLiveTrade from './Components/Mobile/Pages/MobileTrade/MobileLiveTrade/MobileMetalsLiveTrade/MobileMetalsLiveTrade';
import MobileStockBinaryTrade from './Components/Mobile/Pages/MobileTrade/MobileBinaryTrade/MobileStockBinaryTrade/MobileStockBinaryTrade';
import MobileStockLiveTrade from './Components/Mobile/Pages/MobileTrade/MobileLiveTrade/MobileStockLiveTrade/MobileStockLiveTrade';

function App() {
  useEffect(()=>{
    Aos.init({duration:1000});
    },[]);

    if(!isMobile){
      return <Desktop />
      }

  return (

    <>
         <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />

    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<MobileLoginRoute><MobileLoginForm /></MobileLoginRoute>} />
      <Route path="register" element={<MobileLoginRoute><MobileRegisterForm /></MobileLoginRoute>} />
      <Route path="/forgot/password" element={<LoginRoute> <ForgetPassword /> </LoginRoute>} />
      <Route path="/reset/password/:id/:token" element={<LoginRoute> <ReSetPassword /> </LoginRoute>} />
          



{/* Mobile  */}

<Route path="/user/" element={<MobileHomeMain />}  >
<Route path="dashboard" element={<UserMobilePrivateRoute> <MobileUserDashboard /> </UserMobilePrivateRoute>} />

<Route path="trade" element={<UserMobilePrivateRoute> <MobileTradePage /> </UserMobilePrivateRoute>} />
 

<Route path="bitcoin/mining" element={<UserMobilePrivateRoute><MobileBitcoinMining /></UserMobilePrivateRoute>} />
<Route path="bitcoin/mining/history" element={<UserMobilePrivateRoute><MobileBitcoinMiningHistory /></UserMobilePrivateRoute>} />
<Route path="gold/mining" element={<UserMobilePrivateRoute><MobileGoldMining /></UserMobilePrivateRoute>} />
<Route path="gold/mining/history" element={<UserMobilePrivateRoute><MobileGoldMiningHistory /></UserMobilePrivateRoute>} />
<Route path="apply/loan" element={<UserMobilePrivateRoute><MobileLoan /></UserMobilePrivateRoute>} />
<Route path="apply/loan/history" element={<UserMobilePrivateRoute><MobileLoanHistory /></UserMobilePrivateRoute>} />
<Route path="loan/confirm/:amount/:id" element={<UserMobilePrivateRoute><MobileLoanConfirmPage /></UserMobilePrivateRoute>} />
<Route path="news" element={<UserMobilePrivateRoute><News /></UserMobilePrivateRoute>} />
<Route path="news/details/:id" element={<UserMobilePrivateRoute><NewsDetails /></UserMobilePrivateRoute>} />


<Route path="assets/deposit" element={<UserMobilePrivateRoute><MobileCryptoDeposit /></UserMobilePrivateRoute>} />
<Route path="deposit/history" element={<UserMobilePrivateRoute><MobileDepositHistory /></UserMobilePrivateRoute>} />
<Route path="assets/withdraw" element={<UserMobilePrivateRoute><MobileKycPrivateRoute>  <MobileWithdrawPage /> </MobileKycPrivateRoute> </UserMobilePrivateRoute>} />
<Route path="withdraw/history" element={<UserMobilePrivateRoute> <MobileWithdrawHistory /></UserMobilePrivateRoute>} />

<Route path="profile/update" element={<UserMobilePrivateRoute><UserProfileUpdate /></UserMobilePrivateRoute>} />
<Route path="password/update" element={<UserMobilePrivateRoute><UserPasswordChange /></UserMobilePrivateRoute>} />

<Route path="id-auth/Kyc" element={<UserPrivateRoute> <MobileKycPage /></UserPrivateRoute>} />
<Route path="transaction/histroy" element={<UserPrivateRoute> <MobileTransaction /></UserPrivateRoute>} />
 
<Route path="assets" element={<UserPrivateRoute> <MobileAssets /></UserPrivateRoute>} />
<Route path="assets/exchange" element={<UserPrivateRoute> <MobileAssetsExchange /></UserPrivateRoute>} />
<Route path="markets" element={<UserPrivateRoute> <Markets /></UserPrivateRoute>} />

<Route path="invest/plan" element={<UserPrivateRoute>  <MobileInvestPlan /> </UserPrivateRoute>} />
<Route path="invest/history" element={<UserPrivateRoute>  <MobileInvestPlanHistory /> </UserPrivateRoute>} />

{/* trade  */} 
<Route path="trade/crypto/:id" element={<UserMobilePrivateRoute> <MobileCryptoBinaryTrade /> </UserMobilePrivateRoute>} />
<Route path="trade/forex/:id" element={<UserMobilePrivateRoute> <MobileForexBinaryTrade /> </UserMobilePrivateRoute>} />
<Route path="trade/metals/:id" element={<UserMobilePrivateRoute> <MobileMetalBinaryTrade /> </UserMobilePrivateRoute>} />
<Route path="trade/stock/:id" element={<UserMobilePrivateRoute> <MobileStockBinaryTrade /> </UserMobilePrivateRoute>} />


{/* live Trade   */}

<Route path="live/trade/crypto/:id" element={<UserMobilePrivateRoute> <MobileCryptoLiveTrade /> </UserMobilePrivateRoute>} />
<Route path="live/trade/forex/:id" element={<UserMobilePrivateRoute> <MobileForexLiveTrade /> </UserMobilePrivateRoute>} />
<Route path="live/trade/metals/:id" element={<UserMobilePrivateRoute> <MobileMetalsLiveTrade /> </UserMobilePrivateRoute>} />
<Route path="live/trade/stocks/:id" element={<UserMobilePrivateRoute> <MobileStockLiveTrade /> </UserMobilePrivateRoute>} />


<Route path="trade/history" element={<UserPrivateRoute><MobileTradeHistory /></UserPrivateRoute>} />
<Route path="live/trade/history" element={<UserPrivateRoute><Orders /></UserPrivateRoute>} />

<Route path="contact" element={<UserMobilePrivateRoute> <MobileContact /> </UserMobilePrivateRoute>} />
<Route path="forex" element={<UserMobilePrivateRoute> <MobileForex /> </UserMobilePrivateRoute>} />
<Route path="stocks" element={<UserMobilePrivateRoute> <MobileStocks /> </UserMobilePrivateRoute>} />

<Route path="platform/apps" element={ <UserMobilePrivateRoute> <MobilePlatformApps /> </UserMobilePrivateRoute>} />
<Route path="slider/details/:id" element={ <UserMobilePrivateRoute> <MobileSilderDetails /> </UserMobilePrivateRoute>} />
<Route path="new/coin" element={ <UserMobilePrivateRoute> <MobileNewCoin /> </UserMobilePrivateRoute>} />
<Route path="new/coin/:id" element={ <UserMobilePrivateRoute> <MobileNewCoinCandle /> </UserMobilePrivateRoute>} />
<Route path="gift" element={ <UserMobilePrivateRoute> <MobileGift /> </UserMobilePrivateRoute>} />
<Route path="history" element={ <UserMobilePrivateRoute> <MobileHistoryPage /> </UserMobilePrivateRoute>} />

</Route>

<Route path="user/announcement" element={ <UserMobilePrivateRoute> <MobileAnnouncement /> </UserMobilePrivateRoute>} />
<Route path="/user/announcement/details/:id" element={ <UserMobilePrivateRoute> <AnnouncementDetails /> </UserMobilePrivateRoute>} />


     


        </Routes>

    </BrowserRouter>
    
    </>
   


  );
}

export default App;