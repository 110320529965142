import React from "react";
const Desktop = () => {
  return (
    <>
      <section className="body-section">
        <div className="container">
          <section className="login-section d-flex align-items-center justify-content-center">
            <div className="h-100 d-flex align-items-center justify-content-center">
              <h3 className="bg-danger py-2 px-4 rounded">
                Please visit using only mobile device!!!
              </h3>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default Desktop;
