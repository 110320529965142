import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import SingleItem from "./SingleItem";
import CustomPaginationMobile from "../../../CustomPagination/CustomPaginationMobile";
import { AuthContext } from "../../../../Contexts/AuthContext/AuthProvider";
import Loader from "../../../Loader/Loader";
import { useNavigate } from "react-router-dom";
import MobileLoanHistory from "./MobileLoanHistory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Loan.css";
import Swal from "sweetalert2";
const MobileLoan = () => {
  const { authUser } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [paginateData, setPaginateData] = useState([]);
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   fetch(`https://api.hyperspacebuddy.com/api/user/loan/package/view`, {
  //     method: "GET",
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setData(data?.data);
  //       setPaginateData(data);
  //       setLoading(false);
  //     });
  // }, []);

  // const handlePage = (event) => {
  //   try {
  //     const pageNumber = event?.selected + 1;
  //     const config = {
  //       headers: {
  //         "content-type": "application/json",
  //       },
  //     };
  //     axios
  //       .get(
  //         `https://api.hyperspacebuddy.com/api/user/loan/package/view/${authUser?._id}?page=${pageNumber}`,
  //         config
  //       )
  //       .then((response) => {
  //         setData(response?.data?.data);
  //         setPaginateData(response?.data);
  //       })
  //       .catch((error) => {});
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    fetch(`https://api.hyperspacebuddy.com/api/user/loan/package/filter/view`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data?.data);
        setPaginateData(data);
        setLoading(false);
      });
  }, []);

  const [filter, setFilter] = useState("");

  const handlePage = (event) => {
    try {
      if (event?.target?.value) {
        setFilter(event?.target?.value);
      }
      const pageNumber = event?.selected + 1;
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };

      if (event?.target?.value) {
        axios
          .get(
            `https://api.hyperspacebuddy.com/api/user/loan/package/filter/view?&page=1&filter=${event?.target?.value}`,
            config
          )
          .then((response) => {
            setData(response?.data?.data);
            setPaginateData(response?.data);
          })
          .catch((error) => {});
      } else {
        axios
          .get(
            `https://api.hyperspacebuddy.com/api/user/loan/package/filter/view?page=${pageNumber}&filter=${filter}`,
            config
          )
          .then((response) => {
            setData(response?.data?.data);
            setPaginateData(response?.data);
          })
          .catch((error) => {});
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Handle submit form

  const refSubmitDis = useRef();
  const [errorMessage, setErrorMessage] = useState([]);
  const navigate = useNavigate();

  const handleSubmitData = async (event) => {
    event.preventDefault();
    refSubmitDis.current.setAttribute("disabled", true);
    const formData = new FormData(event.currentTarget);
    const InpuData = Object.fromEntries(formData);
    try {
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };

      await axios
        .post(
          `https://api.hyperspacebuddy.com/api/user/loan/amount/check`,
          { ...InpuData, user_id: authUser?._id },
          config
        )
        .then((data) => {
          const packageId = data?.data?.data?._id;
          const Location = `/user/loan/confirm/${data?.data?.amount}/${packageId}`;
          navigate(Location);
          refSubmitDis.current.removeAttribute("disabled");
          event.target.reset();
        })
        .catch((error) => {
          if(error?.response?.data?.KYCmessage){
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: `${error?.response?.data.KYCmessage}`,
            });
          }else{
            setErrorMessage(error?.response?.data);

          }
          refSubmitDis.current.removeAttribute("disabled");
        });
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return (
      <>
        <Loader />
      </>
    );
  } else {
    return (
      <>
        <section class="user-loan-area">
          <div class="container col-12 text-right py-2">
            <div className="order-filter-area pt-3">
              <p>
                <FontAwesomeIcon icon="fa-solid fa-filter" /> Filter
              </p>

              <div className="form-group">
                <select className="form-select" onChange={handlePage}>
                  <option selected value="createdAt">
                    Newest
                  </option>
                  <option value="-createdAt">Older </option>
                </select>
              </div>
            </div>
            <div class="row g-4 justify-content-center">
              {data?.length !== 0 ? (
                data.map((data, index) => {
                  if (data) {
                    return (
                      <SingleItem
                        data={data}
                        index={index}
                        key={data._id}
                        handleSubmitData={handleSubmitData}
                        refSubmitDis={refSubmitDis}
                        errorMessage={errorMessage}
                      ></SingleItem>
                    );
                  }
                })
              ) : (
                <>
                  <div className="text-center h-50">
                    <h6> Data not found</h6>
                  </div>
                </>
              )}
            </div>
            <div className="pt-3">
              {/* paginateLinks */}
              <CustomPaginationMobile
                data={paginateData}
                handlePage={handlePage}
              ></CustomPaginationMobile>
              {/* paginateLinks */}
            </div>
          </div>

          <MobileLoanHistory />
        </section>
      </>
    );
  }
};

export default MobileLoan;
